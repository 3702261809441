import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import Remark from 'react-markdown'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import Text from '@components/Text'
import Picture from '@components/Picture'
import CTA from '@components/CTA'

import styles from './Markdown.module.scss'

const cx = classnames.bind(styles)

export const Paragraph = ({ children }) => (
  <Text className={cx('paragraph')} type="b1" tag="p">
    {children}
  </Text>
)

Paragraph.propTypes = {
  children: PropTypes.node,
}

export const Quotes = ({ children }) => {
  return (
    <Text className={cx('blockquote')} tag="h2" type="h2Serif">
      {children}
    </Text>
  )
}

Quotes.propTypes = {
  children: PropTypes.node,
}

export const Table = ({ children }) => {
  return (
    <table className={cx('styled-table')}>
      <tbody>{children}</tbody>
    </table>
  )
}

Table.propTypes = {
  children: Table.node,
}


export const Strong = ({ children }) => (
  <Text className={cx('bold')} type="b1Bold" tag="strong">
    {children}
  </Text>
)

Strong.propTypes = {
  children: PropTypes.node,
}

export const Emphasis = ({ children }) => (
  <Text className={cx('emphasis')} tag="em">
    {children}
  </Text>
)

Emphasis.propTypes = {
  children: PropTypes.node,
}

export const Link = ({ children, href, ...props }) => (
  <CTA className={cx('link')} type="tertiary" to={href} {...props}>
    {children}
  </CTA>
)

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
}

export const List = ({ tag: Tag, ...rest }) => <Tag className={cx('richList')} {...rest} />

List.propTypes = {
  tag: PropTypes.string.isRequired,
}

export const OrderedList = ({ ...props }) => <List tag="ol" {...props} />

export const UnorderedList = ({ ...props }) => <List tag="ul" {...props} />

export const Heading = ({ children }) => (
  <Text className={cx('headline')} type="h2Serif" tag="h2">
    {children}
  </Text>
)

Heading.propTypes = {
  children: PropTypes.node,
}

export const Heading1 = ({ children }) => (
  <Text type="h1" tag="h1">
    {children}
  </Text>
)

Heading1.propTypes = {
  children: PropTypes.node,
}

export const Heading2 = ({ children }) => (
  <Text type="h2Bold" tag="h2">
    {children}
  </Text>
)

Heading2.propTypes = {
  children: PropTypes.node,
}

export const Heading3 = ({ children }) => (
  <Text type="h3" tag="h3">
    {children}
  </Text>
)

Heading3.propTypes = {
  children: PropTypes.node,
}

export const Heading4 = ({ children }) => (
  <Text type="h4" tag="h4">
    {children}
  </Text>
)

Heading4.propTypes = {
  children: PropTypes.node,
}

export const Image = ({ src, alt, className }) => {
  return <img className={cx('image', className)} src={src} alt={alt} />
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
}

export const EmbedMedia = ({ src, name }) => {
  return <iframe className={cx('media-iframe')} src={src} title={name} frameBorder="0" allow="fullscreen" />
}

EmbedMedia.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
}

const renderEmbedMedia = function render(node) {
  const vimeoId =
    node.data &&
    node.data.target &&
    node.data.target.fields &&
    node.data.target.fields.vimeoVideoId &&
    node.data.target.fields.vimeoVideoId['en-US']

  const name =
    node.data &&
    node.data.target &&
    node.data.target.fields &&
    node.data.target.fields.name &&
    node.data.target.fields.name['en-US']

  if (vimeoId) {
    return <EmbedMedia src={`https://player.vimeo.com/video/${vimeoId}`} name={name} />
  }
}

export const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: function render(text) {
      return <Strong>{text}</Strong>
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: function render(node, children) {
      return <Paragraph>{children}</Paragraph>
    },
    [BLOCKS.HEADING_1]: function render(node, children) {
      return <Heading1>{children}</Heading1>
    },
    [BLOCKS.HEADING_2]: function render(node, children) {
      return <Heading2>{children}</Heading2>
    },
    [BLOCKS.HEADING_3]: function render(node, children) {
      return <Heading3>{children}</Heading3>
    },
    [BLOCKS.HEADING_4]: function render(node, children) {
      return <Heading4>{children}</Heading4>
    },
    [INLINES.HYPERLINK]: function render(node, children) {
      return <Link href={node.data.uri}>{children}</Link>
    },
    [BLOCKS.UL_LIST]: function render(node, children) {
      return <UnorderedList>{children}</UnorderedList>
    },
    [BLOCKS.OL_LIST]: function render(node, children) {
      return <OrderedList>{children}</OrderedList>
    },
    [BLOCKS.QUOTE]: function render(node, children) {
      return <Quotes>{children}</Quotes>
    },
    [BLOCKS.EMBEDDED_ASSET]: function render(node) {
      const src =
        node.data &&
        node.data.target &&
        node.data.target.fields &&
        node.data.target.fields.file &&
        node.data.target.fields.file['en-US'].url
      const alt =
        node.data &&
        node.data.target &&
        node.data.target.fields &&
        node.data.target.fields.title &&
        node.data.target.fields.title['en-US']
      return <Picture src={src} alt={alt} className={cx('image')} />
    },
    [BLOCKS.EMBEDDED_ENTRY]: renderEmbedMedia,
    [INLINES.EMBEDDED_ENTRY]: renderEmbedMedia,
    [BLOCKS.TABLE]: function render(node, children) {
      console.log(node)
      return <Table className={cx('styled-table')}>{children}</Table>
    },
    [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
    [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th className={cx('th')}>{children}</th>,
  },
}

export const Markdown = ({ children, ...props }) => {
  const renderers = {
    paragraph: Paragraph,
    strong: Strong,
    emphasis: Emphasis,
    link: Link,
    list: options => {
      const fn = options.ordered ? OrderedList : UnorderedList
      return fn(options)
    },
    heading: options => {
      switch (options.level) {
        case 1:
          return Heading1(options)
        case 2:
          return Heading2(options)
        case 3:
          return Heading3(options)
        case 4:
          return Heading4(options)
        default:
          return Heading4(options)
      }
    },
  }

  const disallowedTypes = [
    'blockquote',
    'thematicBreak',
    'delete',
    'image',
    'imageReference',
    'inlineCode',
    'code',
    'html',
    'virtualHtml',
    'parsedHtml',
  ]

  return <Remark source={children} renderers={renderers} disallowedTypes={disallowedTypes} {...props} />
}

Markdown.propTypes = {
  children: PropTypes.string,
}

export const InlineMarkdown = ({ children, ...props }) => {
  const renderers = {
    paragraph: Paragraph,
    strong: Strong,
    emphasis: Emphasis,
    link: Link,
  }

  const disallowedTypes = [
    'blockquote',
    'thematicBreak',
    'delete',
    'image',
    'imageReference',
    'table',
    'tableHead',
    'tableBody',
    'tableRow',
    'tableCell',
    'list',
    'heading',
    'inlineCode',
    'code',
    'html',
    'virtualHtml',
    'parsedHtml',
  ]

  return <Remark source={children} renderers={renderers} disallowedTypes={disallowedTypes} {...props} />
}

InlineMarkdown.propTypes = {
  children: PropTypes.string,
}
